import './assets/main.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import {
	AuthProvider,
	authRequestInterceptor,
	authResponseInterceptor,
} from './shared/auth';

import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

// Register axios interceptors
axios.interceptors.request.use(
	authRequestInterceptor.onSuccess,
	authRequestInterceptor.onFailed
);
axios.interceptors.response.use(
	authResponseInterceptor.onSuccess,
	authResponseInterceptor.onFailed
);

ReactDOM.render(
	<BrowserRouter basename={baseUrl}>
		<AuthProvider>
			<App />
		</AuthProvider>
	</BrowserRouter>,
	rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
