const InfoDetails = ({ details }) => {
	return (
		<div className='grid md:grid-cols-2 sm:grid-cols-1 gap-6'>
			{details &&
				details.map((detail, index) => (
					<div className='text-gray-900' key={index}>
						<div className='text-xs font-bold'>{detail.title}:</div>
						{detail.value}
					</div>
				))}
		</div>
	);
};
export default InfoDetails;
