import { SECURE_PHRASE } from './auth';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

var CryptoJS = require('crypto-js');

export const classNames = (...classes) => {
	return classes.filter(Boolean).join(' ');
};

export const validate = (values, rules) => {
	const errors = {};

	Object.keys(rules).forEach((rule) => {
		if (rules[rule].required && (!values[rule] || values[rule].length === 0)) {
			errors[rule] = 'Verplicht veld';
		}

		if (
			rules[rule].notEquals &&
			values[rules[rule].notEquals.prop] === values[rule]
		) {
			errors[rule] =
				rules[rule].notEquals.error ??
				`Moet niet gelijk aan ${rules[rule].notEquals}`;
		}

		if (
			rules[rule].equals &&
			values[rule] !== values[rules[rule].equals.prop]
		) {
			errors[rule] =
				rules[rule].equals.error ??
				`Moet gelijk zijn aan ${rules[rule].equals}`;
		}

		if (
			rules[rule].regex &&
			!new RegExp(rules[rule].regex.pattern).test(values[rule])
		) {
			errors[rule] = rules[rule].regex.error;
		}
	});

	return errors;
};

export const useQuery = () => {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * Encrypts a string .
 * @important This is a client-side encryption. It's NOT secure.
 * @param {string} text Text to be encrypted
 * @returns A string with the encrypted text
 */
export const encrypt = (text) => {
	return CryptoJS.AES.encrypt(text, SECURE_PHRASE).toString();
};

/**
 * Decrypts a string.
 * @important This is a client-side encryption. It's NOT secure.
 * @param {string} text Text to be decrypted
 * @returns A string with the decrypted text
 */
export const decrypt = (text) => {
	return CryptoJS.AES.decrypt(text, SECURE_PHRASE).toString(CryptoJS.enc.Utf8);
};
