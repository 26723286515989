import { Tab } from '@headlessui/react';
import { classNames } from '../shared/utilities';

export const Tabs = ({ items }) => {
	return (
		<Tab.Group>
			<Tab.List className='flex text-sm'>
				{items.map((item, index) => (
					<Tab
						key={index}
						className={({ selected }) =>
							classNames(
								'py-3 px-5  rounded-t-lg',
								!selected ? 'hover:bg-gray-200/50' : '',
								selected ? 'bg-gray-200 font-semibold text-gray-700' : ''
							)
						}
					>
						{item.title}
					</Tab>
				))}
			</Tab.List>
			<Tab.Panels className='bg-gray-200'>
				{items.map((item, index) => (
					<Tab.Panel key={index} className='p-5'>
						{item.content}
					</Tab.Panel>
				))}
			</Tab.Panels>
		</Tab.Group>
	);
};
