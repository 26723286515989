import Loading from './Loading';
import { classNames } from '../shared/utilities';

const Button = ({
	onClick,
	className,
	children,
	type,
	loading,
	disabled,
	submit,
	reset,
}) => {
	return (
		<button
			type={submit ? 'submit' : reset ? 'reset' : 'button'}
			onClick={onClick}
			disabled={loading || disabled}
			className={classNames(
				'text-xs py-2 px-3 rounded-md font-semibold transition-colors duration-300',
				className,
				!type
					? 'bg-gray-300 text-gray-800 hover:bg-gray-400/75 active:bg-gray-400'
					: '',
				type === 'primary'
					? 'bg-red-600 text-white hover:bg-red-700 active:bg-red-800'
					: ''
			)}
		>
			{loading ? <Loading className='h-5 w-10 mx-auto text-white' /> : children}
		</button>
	);
};
export default Button;
