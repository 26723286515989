import Alert from '../components/Alert'
import Button from '../components/Button'
import Page from '../components/Page'
import TextBox from '../components/TextBox'
import { changePassword } from '../shared/api'
import { useState } from 'react'
import { validate } from '../shared/utilities'

const rules = {
	currentPassword: {
		required: true
	},
	password: {
		required: true,
		notEquals: {
			prop: 'currentPassword',
			error: 'Nieuw wachtwoord kan niet gelijk zijn aan huidig wachtwoord.'
		},
		regex: {
			pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[~!@#$%^&*()-_=+?\\.])(?=.{8,})',
			error: 'Het wachtwoord is ongeldig.'
		}
	},
	passwordConfirm: {
		required: true,
		equals: {
			prop: 'password',
			error: 'Wachtwoorden komen niet overeen.'
		}
	}
}

const defaultState = {
	currentPassword: '',
	password: '',
	passwordConfirm: ''
}

const defaultErrorState = {
	currentPassword: null,
	password: null,
	passwordConfirm: null
}

const Password = () => {
	const [credentials, setCredentials] = useState(defaultState)
	const [errors, setErrors] = useState(defaultErrorState)
	const [loading, setLoading] = useState(false)
	const [invalid, setInvalid] = useState(false)
	const [success, setSuccess] = useState(false)

	const PASSWORD_POLICY_ERROR_CODE = '0x800708C5'

	const handleSubmit = async (e) => {
		e.preventDefault()

		const errors = validate(credentials, rules)
		setErrors(errors)

		// Invalid
		if (Object.keys(errors).length > 0) {
			return
		}

		try {
			setLoading(true)
			await changePassword(credentials.currentPassword, credentials.password, credentials.passwordConfirm)

			setSuccess(true)
			setInvalid(false)
			setCredentials({ ...defaultState })
		} catch (err) {
			if (err.response.status === 500 && err.response.data.Error.includes(PASSWORD_POLICY_ERROR_CODE)) {
				setErrors({ ...errors, password: 'Nieuw wachtwoord kan niet gelijk zijn aan een eerder gebruikt wachtwoord.' })
			} else {
				setInvalid(true)
				console.log(err)
			}
		} finally {
			setLoading(false)
		}
	}

	const handleOnChange = (e) => {
		const { name, value } = e.target
		setCredentials({ ...credentials, [name]: value })
		setInvalid(false)
		setErrors({ ...errors, [name]: null })
	}

	const handleOnReset = (e) => {
		setCredentials({ ...defaultState })
		setErrors({ ...defaultErrorState })
		setSuccess(false)
		setInvalid(false)
	}

	return (
		<Page title='Wachtwoord wijzigen'>
			{invalid && (
				<Alert type='danger' className='mb-4 p-3'>
					Er is een fout opgetreden. Probeer het opnieuw.
				</Alert>
			)}

			{success && (
				<Alert type='success' className='mb-4 p-3'>
					Wachtwoord is succesvol gewijzigd.
				</Alert>
			)}
			<form onSubmit={handleSubmit} onReset={handleOnReset}>
				<div className='grid grid-cols-2 gap-5'>
					<div>
						<TextBox
							className='mb-4'
							name='currentPassword'
							title='Huidige wachtwoord'
							type='password'
							value={credentials.currentPassword}
							onChange={handleOnChange}
							error={errors.currentPassword}
							required
						/>
						<TextBox
							className='mb-4'
							name='password'
							title='Nieuwe wachtwoord'
							type='password'
							value={credentials.password}
							onChange={handleOnChange}
							error={errors.password}
							required
						/>
						<TextBox
							name='passwordConfirm'
							title='Nieuwe wachtwoord bevestigen'
							type='password'
							value={credentials.passwordConfirm}
							onChange={handleOnChange}
							error={errors.passwordConfirm}
							required
						/>
					</div>

					<div className='self-center'>
						<p className='text-xs mb-4'>
							Uw wachtwoord is voor het laatst gewijzigd op 13-9-2021 13:16:41. Na het wijzigen van uw wachtwoord blijft het oude
							wachtwoord nog maximaal een uur geldig.
						</p>
						<Alert className='text-xs p-3'>
							Uw wachtwoord moet aan de volgende eisen voldoen:
							<ul className='list-disc list-inside'>
								<li>Minimaal 8 karakters lang </li>
								<li>Minimaal één cijfer</li>
								<li>Minimaal één hoofdletter</li>
								<li>Minimaal één speciaal teken, keuze uit: ~!@#$%^&*()-_=+?\</li>
								<li>Niet gelijk zijn aan een eerder gebruikt wachtwoord</li>
							</ul>
						</Alert>
					</div>
				</div>
				<div className='mt-4'>
					<Button type='primary' className='mr-4' submit loading={loading}>
						Opslaan
					</Button>
					<Button reset>Annuleren</Button>
				</div>
			</form>
		</Page>
	)
}
export default Password
