import Navbar from './Navbar';

const year = new Date().getFullYear();

const Layout = ({ children }) => {
	return (
		<div className='flex flex-col h-screen'>
			<div className='container mx-auto bg-white mt-3 md:w-4/5 xl:w-3/5 2xl:w-2/5'>
				<Navbar />
				<div>{children}</div>
			</div>
			<div className='text-sm text-center mb-3 py-4'>
				<a href='https://www.technoberg.nl' className='mr-1'>
					Technoberg IT Services
				</a>
				&copy; {year}
				Alle rechten voorbehouden.
			</div>
		</div>
	);
};

export default Layout;
