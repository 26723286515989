import { classNames } from '../shared/utilities';

const Alert = ({ children, type, className }) => {
	return (
		<div
			className={classNames(
				className,
				!type ? 'bg-gray-300 text-gray-800' : '',
				type === 'success' ? 'bg-green-300 text-green-800' : '',
				type === 'info' ? 'bg-blue-300 text-blue-800' : '',
				type === 'warning' ? 'bg-orange-300 text-orange-800' : '',
				type === 'danger' ? 'bg-red-300 text-red-800' : ''
			)}
		>
			{children}
		</div>
	);
};
export default Alert;
