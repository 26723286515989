import { AuthProvider, AuthorizedRoute, useAuth } from './shared/auth';

import History from './pages/History';
import Home from './pages/Home';
import Layout from './components/Layout';
import Login from './pages/Login';
import Password from './pages/Password';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router';

const App = () => {
	const auth = useAuth();

	return (
		<Layout>
			<Route exact path='/login' component={Login} />
			<AuthorizedRoute>
				<Route exact path='/' component={Home} />
				<Route exact path='/password' component={Password} />
				<Route exact path='/history' component={History} />
			</AuthorizedRoute>
		</Layout>
	);
};
export default App;
