import { Redirect, useLocation } from 'react-router-dom';
import { createContext, useContext, useState } from 'react';
import { decrypt, encrypt } from './utilities';

import axios from 'axios';

const USER_KEY = 'ADIM_USER';

export const SECURE_PHRASE = 'ct4nG3EDPkm5cM5wo3iaUQ';
export const AuthContext = createContext(null);

const storeUser = (user) => {
	const encoded = encrypt(JSON.stringify(user));
	localStorage.setItem(USER_KEY, encoded);
};

const clearUser = () => {
	localStorage.removeItem(USER_KEY);
};

export const authRequestInterceptor = {
	onSuccess: (req) => {
		const user = getCurrentUser();
		if (user && Object.keys(user).length > 0) {
			req.headers.Authorization = `Bearer ${user.token}`;
		}
		return req;
	},
	onFailed: (error) => Promise.reject(error),
};

export const authResponseInterceptor = {
	onSuccess: (res) => res,
	onFailed: (error) => {
		if ([401, 403].indexOf(error.response.status) !== -1) {
			clearUser();
			window.location.reload();
		}
		return Promise.reject(error);
	},
};

/**
 * Gets the user from local storage.
 * @remarks This should only used externally. In components, use `useAuth` instead.
 * @returns logged in user or null
 */
export const getCurrentUser = () => {
	const user = localStorage.getItem(USER_KEY);
	if (!user) return null;

	const decoded = decrypt(user);
	return JSON.parse(decoded);
};

/**
 * Logins a user with the given credentials and stores the user in local storage.
 * @param {string} username Username
 * @param {string} password Password
 */
const requestLogin = async (username, password) => {
	try {
		const res = await axios.post('/api/account/login', { username, password });

		const user = { ...res.data };

		storeUser(user);

		// Is MFA requested
		if (user.mfaUri) {
			window.location = user.mfaUri;
		}
	} catch (err) {
		console.log(err);
	}
};

/**
 * Verifies the MFA code and logs the user in.
 * @param {string} state MFA state
 * @returns {Promise<any>} User object or null
 */
const requestMfaVerification = async (state) => {
	try {
		const res = await axios.get(`/api/account/duo_callback?state=${state}`);
		const user = { ...res.data };
		storeUser(user);
		return user;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(getCurrentUser);

	const login = (username, password) => {
		return requestLogin(username, password);
	};

	const logout = () => {
		clearUser();
		setUser(null);
	};

	const verifyMfa = async (state) => {
		const res = await requestMfaVerification(state);
		setUser(res);
		return res;
	};

	return (
		<AuthContext.Provider value={{ user, login, logout, verifyMfa }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuth must be used within a AuthProvider');
	}
	return context;
};

export const AuthorizedRoute = ({ children }) => {
	const auth = useAuth();
	let location = useLocation();

	if (!auth.user && location.pathname !== '/login') {
		return <Redirect to='/login' state={{ from: location }} />;
	}

	return children;
};
