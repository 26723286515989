import { classNames } from '../shared/utilities';

const TextBox = ({
	name,
	title,
	type,
	onChange,
	value,
	required,
	error,
	className,
}) => {
	return (
		<div className={className}>
			<label htmlFor={name} className='display-block text-gray-700'>
				{title}
				{!!required && <span className='text-red-600 ml-1'>*</span>}
			</label>
			<input
				className={classNames(
					'display-block border border-gray-300 p-1 rounded-sm w-full',
					error ? 'border-red-300 bg-red-100 text-red-800' : ''
				)}
				name={name}
				type={type}
				onChange={onChange}
				value={value}
			/>
			{error && <span className='text-xs text-red-800'>* {error}</span>}
		</div>
	);
};
export default TextBox;
