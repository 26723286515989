import Loading from './Loading';
import { classNames } from '../shared/utilities';

const Page = ({ title, children, size, loading }) => {
	return (
		<>
			<div
				className={classNames(
					'p-6',
					size === 'small' ? 'sm:w-full md:w-2/3 xl:w-1/2 mx-auto' : ''
				)}
			>
				<h2 className={'text-2xl font-bold pb-5'}>{title}</h2>
				{loading ? (
					<div className='py-7'>
						<Loading className='w-8 h-8 text-red-600' />
					</div>
				) : (
					<>{children}</>
				)}
			</div>
		</>
	);
};
export default Page;
