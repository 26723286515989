import { TabItem, Tabs } from '../components/Tabs';
import { useEffect, useState } from 'react';

import InfoDetails from '../components/InfoDetails';
import Page from '../components/Page';
import { getAccount } from '../shared/api';

const Home = () => {
	const [loading, setLoading] = useState(true);
	const [details, setDetails] = useState([]);

	const mapDetails = (res) => {
		return [
			{
				title: 'Weergavenaam',
				value: res.fullName,
			},
			{
				title: 'Voornaam',
				value: res.firstName,
			},
			{
				title: 'Achternaam',
				value: res.lastName,
			},
			{
				title: 'Emailadres',
				value: res.email,
			},
			{
				title: 'Omschrijving',
				value: res.description,
			},
			{
				title: 'Laatst mislukte inlogpoging',
				value: new Date(res.lastFailedLogin).toLocaleString(),
			},
			{
				title: 'Laatste wachtwoord wijziging',
				value: new Date(res.lastPasswordChange).toLocaleString(),
			},
		];
	};

	useEffect(() => {
		setLoading(true);
		getAccount()
			.then((res) => {
				setDetails(mapDetails(res.data));
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				setLoading(false);
			});
	}, []);

	const tabItems = [
		{
			title: 'Algemeen',
			content: <InfoDetails details={details} />,
		},
	];

	return (
		<Page title='Accountgegevens' loading={loading}>
			<Tabs items={tabItems} className='mt-3' />
		</Page>
	);
};

export default Home;
