import {
	DocumentTextIcon,
	HomeIcon,
	LockClosedIcon,
	LogoutIcon,
} from '@heroicons/react/solid';

import { NavLink } from 'react-router-dom';
import { classNames } from '../shared/utilities';
import logo from '../assets/images/tb_logo.png';
import { useAuth } from '../shared/auth';

const NavbarLink = ({ to, icon, title, onClick }) => {
	return (
		<NavLink
			exact
			to={to}
			onClick={onClick}
			className={(isActive) =>
				classNames(
					'transition-colors duration-300',
					isActive
						? 'text-white hover:text-gray-200 text-center'
						: 'text-gray-400 hover:text-gray-500 text-center'
				)
			}
		>
			{icon}
			{title}
		</NavLink>
	);
};

const Navbar = () => {
	const auth = useAuth();

	const handleLogout = (e) => {
		e.preventDefault();
		auth.logout();
	};

	return (
		<div>
			<div className='bg-black p-10'>
				<img src={logo} alt='logo' className='w-2/6' />
			</div>
			<div className='flex flex-row bg-gray-900 px-9 py-4 text-gray-300'>
				{auth.user && (
					<>
						<div className='flex flex-row gap-12 text-sm'>
							<NavbarLink
								to='/'
								title='Home'
								icon={<HomeIcon className='w-5 mx-auto' />}
							/>
							<NavbarLink
								to='/password'
								title='Wachtwoord'
								icon={<LockClosedIcon className='w-5 mx-auto' />}
							/>
							{/*<NavbarLink*/}
							{/*	to='/history'*/}
							{/*	title='Historie'*/}
							{/*	icon={<DocumentTextIcon className='w-5 mx-auto' />}*/}
							{/*/>*/}
							<NavbarLink
								to='/logout'
								title='Uitloggen'
								onClick={handleLogout}
								icon={<LogoutIcon className='w-5 mx-auto' />}
							/>
						</div>
						<div className='grow text-right self-center'>
							Welkom, {auth.user.displayName}
						</div>
					</>
				)}
			</div>
		</div>
	);
};

export default Navbar;
