import { Redirect, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useQuery, validate } from '../shared/utilities';

import Alert from '../components/Alert';
import Button from '../components/Button';
import Page from '../components/Page';
import TextBox from '../components/TextBox';
import { useAuth } from '../shared/auth';
import { useHistory } from 'react-router-dom';

const rules = {
	username: {
		required: true,
	},
	password: {
		required: true,
	},
};

const Login = () => {
	const [credentials, setCredentials] = useState({
		username: '',
		password: '',
	});
	const [errors, setErrors] = useState({
		username: null,
		password: null,
	});
	const [loading, setLoading] = useState(false);
	const [pageLoading, setPageLoading] = useState(false);
	const [invalid, setInvalid] = useState(false);
	const auth = useAuth();
	let query = useQuery();
	let history = useHistory();

	const handleOnChange = (e) => {
		const { name, value } = e.target;
		setCredentials({ ...credentials, [name]: value });
		setInvalid(false);
	};

	const handleLogin = async (e) => {
		e.preventDefault();

		const errors = validate(credentials, rules);
		setErrors(errors);

		// Invalid
		if (Object.keys(errors).length > 0) {
			return;
		}

		try {
			setLoading(true);
			await auth.login(credentials.username, credentials.password);
		} catch (err) {
			setInvalid(true);
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const state = query.get('state');
		if (state) {
			setPageLoading(true);
			auth
				.verifyMfa(query.get('state'))
				.then(() => {
					history.push('/');
				})
				.catch((error) => {
					query.delete('state');
					history.replace({ search: query });
				})
				.finally(() => setPageLoading(false));
		}
	}, []);

	return (
		<>
			{!query.get('state') && auth.user ? (
				<Redirect to='/' />
			) : (
				<Page
					title='Inloggen bij Technoberg Identity Management'
					size='small'
					loading={pageLoading}
				>
					<form onSubmit={handleLogin}>
						<div>
							<p className='mb-4 text-sm'>
								Binnen de ID Manager kunt u uw Active Directory account
								bekijken, gegevens aanpassen en uw wachtwoord wijzigen.
							</p>
							{invalid && (
								<Alert type='danger' className='mb-4 p-3'>
									Incorrecte gebruikersnaam of wachtwoord. Probeer het opnieuw.
								</Alert>
							)}
							<div>
								<TextBox
									className='mb-4'
									name='username'
									title='Gebruikersnaam'
									onChange={handleOnChange}
									error={errors.username}
									required
								/>
								<TextBox
									className='mb-4'
									name='password'
									title='Wachtwoord'
									type='password'
									onChange={handleOnChange}
									error={errors.password}
									required
								/>
							</div>
						</div>
						<div className='mt-4'>
							<Button type='primary' className='mr-4' submit loading={loading}>
								Log in
							</Button>
						</div>
					</form>
				</Page>
			)}
		</>
	);
};

export default Login;
