import Page from '../components/Page';
import Table from '../components/Table';

const columns = [
	{
		title: 'Datum',
		key: 'date',
	},
	{
		title: 'Actie',
		key: 'action',
	},
];

const data = [
	{
		date: '13-9-2021 13:16:41',
		action: 'Wachtwoord wijzigen',
	},
	{
		date: '13-9-2021 13:16:41',
		action: 'Wachtwoord wijzigen',
	},
	{
		date: '13-9-2021 13:16:41',
		action: 'Wachtwoord wijzigen',
	},
];

const History = () => {
	return (
		<Page title='Historische gegevens'>
			<Table
				columns={columns}
				data={data.concat(data).concat(data).concat(data)}
			/>
		</Page>
	);
};
export default History;
