const Table = ({ columns, data, className }) => {
	return (
		<table className='w-full table-auto'>
			<thead className='text-xs text-gray-300 '>
				<tr>
					{columns.map((column, index) => (
						<th className='p-2 whitespace-nowrap text-left' key={index}>
							{column.title}
						</th>
					))}
				</tr>
			</thead>
			<tbody className='text-sm divide-y divide-gray-200'>
				{data.map((row, rowIndex) => (
					<tr key={rowIndex}>
						{columns.map((column, columnIndex) => (
							<td className='p-2 whitespace-nowrap' key={columnIndex}>
								{row[column.key]}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default Table;
