import axios from 'axios';

export const getAccount = async () => {
	try {
		const res = await axios.get('/api/account');
		return res;
	} catch (err) {
		console.log(err);
	}
};

export const getHistory = async () => {
	try {
		const res = await axios.get('/api/history');
		return res.data;
	} catch (err) {
		console.log(err);
	}
};

export const resetPassword = async (username, password) => {
	try {
		const res = await axios.post('/api/account/reset', { username, password });
		return res;
	} catch (err) {
		console.log(err);
	}
};

export const changePassword = async (
	oldPassword,
	newPassword,
	confirmNewPassword
) => {
	return await axios.post('/api/account/change_password', {
		currentPassword: oldPassword,
		newPassword,
		confirmNewPassword,
	});
};
